import RSVPConfigQuestionAns from './RSVPConfigQuestionAns';

export default class RSVPConfigQuestion {
    constructor(evntId, questionId, qstnText, sortOrder, required, answers) {
      this.evntId = evntId;
      this.questionId = questionId;
      this.text = qstnText;
      this.sortOrder = sortOrder;
      this.required = required;
      this.answers = answers;
    }

    addYesNo() {
        this.answers.push(
            new RSVPConfigQuestionAns(
                this.questionId,
                0,
                1,
                true,
                'Yes'        
            )
        );
        this.answers.push(
            new RSVPConfigQuestionAns(
                this.questionId,
                0,
                2,
                true,
                'No'        
            )
        );
    }
  }