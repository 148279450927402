import  Home  from './pages/Home';
import  NoRSVPId  from './pages/NoRSVPId';
import './App.css';

function App() {
  // Get URL Parms
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const newParams = new URLSearchParams();
  for (const [name, value] of params) {
    newParams.append(name.toLowerCase(), value);
  }

  let rsvpId = newParams.get('rsvpid');

  return (
    // if evtId found goto to home page, else show error
    rsvpId === null || rsvpId === undefined ? <NoRSVPId /> : <Home rsvpId={rsvpId} />
  );
}

export default App;
