import React, { useEffect, useState } from 'react';
import AppHeader from '../components/Header';
import AppFooter from '../components/Footer';

import QuestionContainer from '../components/QuestionContainer';
import { getRSVPQuestionaire, saveRSVPQuestionaire, retrieveMarketingEventInviteeByRSVPIdentifier } from '../api/dnsServices';

import '../css/Home.css'

export default function Home(props) {

    const [rsvpId] = useState(props.rsvpId);                    // Set our state vairable for RSVPId
    const [evntId, setEvntId] = useState(null);                 // Set our state vairable for EventId
    const [inviteeId, setInviteeId] = useState(null);                 // Set our state vairable for EventId
    const [statusMsg, setStatusMsg] = useState('');             // Init status msg to nothing
    const [statusViz, setStatusViz] = useState('hide');         // Hide the status bar
    const [loadErrorViz, setLoadErrorViz] = useState('hide');   // Hide the load error 
    const [loadErrorMsg, setLoadErrorMsg] = useState('');       // set load error to nothing
    const [rsvpConfig, setRSVPConfig] = useState({});           // set our RSVP to empty nothing
    const [height, setHeight] = useState(window.innerHeight);
    const [statusMsgId, setStatusMsgId] = useState('statusErrorMsg');
    const [error, setError] = useState(false);
    const [showsavebtn,setShowsavebtn] = useState(false);
    

    // Called on page open. Initialize state RSVP config 
    useEffect(() => {
        var resp;

        document.title = "Ameriprise Event Questionaire"
        
        async function fetchData() {
            resp = await retrieveMarketingEventInviteeByRSVPIdentifier(rsvpId);
            if (!resp.status) {
                errorLoadingEvent({ message: resp.errMsg})    
            }
            else {
                setRes(resp.evtId, resp.inviteeId);                
            }
        }
        fetchData();
            
        
        window.addEventListener("resize", updateWidthAndHeight);
        return () => {
            window.removeEventListener("resize", updateWidthAndHeight);
          
        }
        // setRSVPConfig(rsvp);
    }, []);         // eslint-disable-line react-hooks/exhaustive-deps

    function setRes(evId, invId) {
          setEvntId(evId);
          setInviteeId(invId);
          getRSVPQuestionaire(evId, setRSVPConfig, showMessageSuccess, errorLoadingEvent);
          updateWidthAndHeight();
          setError(false);
      }

    // Handle status message animation when status msg is set
       useEffect(() => {
        if (statusViz !== 'show') {
            return;
        }
      }, [statusViz]);

      useEffect(() => {
        updateWidthAndHeight();
      }, [rsvpConfig]);

      function errorLoadingEvent(msg) {
        setLoadErrorViz('show marginTop40');
        setLoadErrorMsg(msg.message);
        setError(true);
        
    }

   
    const updateWidthAndHeight = () => {
        try {
            const h = document.getElementById('ftrContainer').offsetTop - document.getElementById('divPanes').offsetTop;
            setHeight(h);
            
        }
        catch {}
}

    function validateRSVP() {
        var answers = [];
        var answered;

        for (var qs = 0; qs < rsvpConfig.questions.length; qs++) {
            let qstn = rsvpConfig.questions[qs];

            answered = false;

            var ele = document.getElementsByName('qstn' + qstn.sortOrder);
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].checked) {
                    answered = true;
                    answers.push({ qstnNbr: rsvpConfig.questions[qs].questionId, ansNbr: rsvpConfig.questions[qs].answers[ele[i].value].answerId });
                }
            }

            if ((qstn.required) && (!answered) && (qstn.answers.length > 0))
            {
                setTimeout(() => showMessageFailure("Please respond to question " + (qs + 1).toString(),false), 1000);
                return { status: false, answers: [] };
            }
        
        }

        return { status: true, answers: answers };

    }

    function saveClicked() {
        const res = validateRSVP();
        if (res.status === true) {
            saveRSVPQuestionaire(evntId, inviteeId, res.answers, showMessageSuccess, showMessageFailure);
        }
    }

    function showMessageSuccess(msg) {
        setError(false);
        setStatusMsg(msg);
        setStatusViz('show');
        setStatusMsgId('statusSuccessMsg');
        updateWidthAndHeight();
        document.getElementById('divEvtInfo').scrollIntoView(false);
        document.getElementById('divBkgnd').scrollIntoView();       
    }

    function showMessageFailure(msg,flag) {
        setShowsavebtn(flag);
        setError(true);
        setStatusMsg(msg);
        setStatusViz('show');
        setStatusMsgId('statusErrorMsg');
        updateWidthAndHeight();
        document.getElementById('divEvtInfo').scrollIntoView(false);
        document.getElementById('divBkgnd').scrollIntoView();
       
    }

    function renderQuestionsPanel() {

        return rsvpConfig.questions.sort((a,b) => a.sortOrder - b.sortOrder).map((qstn, index) => {
            return qstn.answers.length < 1 ? null 
            :
                <QuestionContainer 
                    key={index}
                    qstnIndex={index}
                    question={qstn} 
                />                            
        
        });
    }

    function renderLoadErrorMsg() {
        return (<div className={loadErrorViz + " divLoadErr"}></div>)
    }

    return (
        <div id="divBkgnd" style={{ height: window.innerHeight /*- 310*/}}>
                <AppHeader />
                <div id="divContentArea">
                    <div id="divLoadError" className={loadErrorViz}>
                        <div id="divLoadErrorHdr" className={loadErrorViz}></div>
                        <div className={loadErrorViz}>{"'" + loadErrorMsg + "'"}</div>
                    </div>
                    <div className="statusBar">
                    <div id={statusMsgId} className={statusViz}>{statusMsg}</div>
                    </div>
                    {
                        (rsvpConfig.questions === undefined) || rsvpConfig.questions.length < 1 ? renderLoadErrorMsg() 
                        :                    
                        <div id="divPanes">
                            
                            <div id="divEvtInfo">
                                <div><strong>Event Details</strong></div>
                                <table id="tblEvtInfo" cellSpacing="0" className="table">
                                    <tbody>
                                        <tr className="brdrBot">
                                            <td className="brdrBot">Date</td>
                                            <td className="evtInfoData brdrBot">{rsvpConfig.evntDate}</td>
                                        </tr>
                                        <tr className="brdrBot">
                                            <td className="brdrBot">Time</td>
                                            <td className="evtInfoData brdrBot">{rsvpConfig.evntTime}</td>
                                        </tr>
                                        <tr className="brdrBot">
                                            <td className="brdrBot">Location</td>
                                            <td className="evtInfoData brdrBot">{rsvpConfig.location}</td>
                                        </tr>
                                        <tr>
                                            <td>RSVP Deadline</td>
                                            <td className="evtInfoData">{rsvpConfig.rsvpEndDate}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="saveLine pad1Right">
                                    <div id="divSaveBtn" className="saveBtn btn btn-primary" onClick={saveClicked} >Submit Responses</div>
                                </div>
                            </div>
                            <div id="divQuestionsContainer" className="container" >
                                <div id="divPlsAnswer">Let us know if you can attend. Respond to the questions below and click submit to send this RSVP to your advisor office.</div>
                                {
                                    rsvpConfig.questions.length > 0 ? renderQuestionsPanel() : null             
                                }
                            </div>
                        </div>
                    } 
                </div>               
             <AppFooter errMsg={error} showSavebtn={showsavebtn}/>

            </div>
    );
}