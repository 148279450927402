import React from 'react';

import '../css/AnswerContainer.css'

export default function AnswerContainer(props) {

    return (
            <div key={props.idx} id="divAnswerLine">
                <label className="divAns lblAns">
                    <input type="radio" value={props.idx} id={props.idx} name={props.name}/>{props.ans.text.length > 0 ? '\u00A0\u00A0\u00A0' + props.ans.text : '\u00A0'}
                </label>
            </div>
    );
}