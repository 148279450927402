import React, { useEffect } from 'react';

import '../css/NoRSVPId.css'

export default function NoRSVPId() {

    useEffect(() => {
        document.title = "Ameriprise Event RSVP - Error"
    }, []);

    return (
        <div className="background"> 
            <div className="center" >
                <div>
                    An RSVP Id is required but was not found on the URL.
                </div>
                <div className="center-ln-2" >
                    Please make sure a valid RSVP Id is passed when opening this page.
                </div>
            </div>
        </div>
    );
}