import RSVPConfigQuestion from './RSVPConfigQuestion';
import RSVPConfigQuestionAns from './RSVPConfigQuestionAns';

export class RSVPConfig {
    constructor(evntId) {
        this.evntId = evntId;
        this.evntName = "";
        this.evntDate = null;
        this.evntStartTime = null;
        this.evntEndTime = null;
        this.evntTime = "";
        this.location = "";
        this.questions = [];
        this.rsvpEndDate=null;
    }
    
    loadFromJSON (evntId, json) {
        this.evntId = evntId;
        this.evntName =json.evntNm;
        this.location = json.evntVenueNm

        // We have some date and time transform's to do.
        let d = new Date(json.evntDt);        
        this.evntDate = (d.getUTCMonth() + 1) + '/' + d.getUTCDate() + '/' + d.getUTCFullYear();

        d = new Date(json.evntSttTs)
        let t = d.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}).toLowerCase() + " - ";
        if (t[0] === '0') { t =t.slice(1) }
        this.evntTime = t;

        d = new Date(json.evntEndTs)
        t = d.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}).toLowerCase();  
        if (t[0] === '0') { t = t.slice(1) }
        this.evntTime = this.evntTime + t;

        if (json.RSVPURLExprTs.slice(0,10)) {
            let rsvpd = new Date(json.RSVPURLExprTs.slice(0,10));
            this.rsvpEndDate = (rsvpd.getUTCMonth() + 1) + '/' + rsvpd.getUTCDate() + '/' + rsvpd.getUTCFullYear();
        } else {
            this.rsvpEndDate = 'Date is not selected Yet';
        }

        json.questions.results.map((qstnJSON, qIndex) => {
                let qstn = new RSVPConfigQuestion(this.evntId, qstnJSON.qstnNbr, qstnJSON.qstnDesc, qstnJSON.srtOrdNbr, qstnJSON.mndtrQstnInd, []);
                qstnJSON.answers.results.map((ansJSON, aIndex) => {
                    // Check for empty question
                    if (ansJSON.ansNbr === null) { return null}
                    let ans = new RSVPConfigQuestionAns(qstn.questionId, ansJSON.ansNbr, ansJSON.srtOrdNbr, true, ansJSON.ansDesc);
                    qstn.answers.push(ans); 
                    return ans;   
                });
                this.questions.push(qstn);
                return qstn;
        });
    }

    createJSON() {
        var out = { qstns: []}
        this.questions.map ((qstn, index)=>{
                out.qstns.push({nbr: qstn.questionId, text: qstn.text, rqd: qstn.required, srt: qstn.sortOrder, answers:[]})
                qstn.answers.map((ans)=>{
                    out.qstns[index].answers.push({nbr: ans.answerId, text: ans.text, srt: ans.sortOrder});
                    return ans;
                })
                return qstn;
        })
       // return out;
        return JSON.stringify(out);
    }
  }