import React from 'react';

import '../css/QuestionContainer.css'
import AnswerContainer from './AnswerContainer';

export default function QuestionContainer(props) {
    return (
            <div id="divQuestionBkgnd">
                    <div id="divQuestionLine">
                        <div id="divQuestNum">{props.question.sortOrder}.&nbsp;</div>
                        <div id="divQuestText">
                            <div className="divQues">{props.question.text.length > 0 ? props.question.text : '\u00A0'}</div>
                            <div id="divQuestRequired" className={props.question.required === true ? 'divQuestRequired' : '' } >{props.question.required === true ? '*' : null}</div>
                        </div>
                    </div>
                    {
                        props.question.answers.sort((a,b) => a.sortOrder - b.sortOrder).map((answer, index)=>{
                            return (
                                <AnswerContainer 
                                    key={index} 
                                    idx={index} 
                                    name={"qstn" + props.question.sortOrder} 
                                    ans={answer} 
                                    qstn={props.question} 
                                    qstnIndex={props.qstnIndex} 
                                />
                            );
                        })
                    }
            </div>
    );
}