import React from 'react';


import '../css/Footer.css';

export default function AppFooter(props) {
    return (
        <acl-footer>
        <footer id="ftrContainer" className="ftrContainer">
        <div id="ftrInfo">
            <p className="ftrFont ftrNotice">The information collected here will be used for planning and communications purposes for this event. 
            Any contact information you input here may be used to invite you to future events.</p>
            <div>
                <table align="center">
                    <tbody>
                        <tr>
                            <td className="ftrLinkTd wd200 ftrFont">
                                <p className="ftrSep">
                                    <a className="Link" href="https://www.ameriprise.com/legal/disclosures" >Corporate entities and important disclosures</a>
                                </p>
                            </td>
                            <td className="ftrLinkTd wd200 ftrFont">
                                <p className="ftrSep">
                                    <a className="Link" href="https://www.ameriprise.com/legal/website-rules" >Website rules, regulations and disclaimers</a>
                                </p>
                            </td>
                            <td className="ftrLinkTd wd110 ftrFont">
                                <p className="ftrSep">
                                    <a className="Link" href="https://www.ameriprise.com/privacy-security-fraud/online-security-guarantee" >Online security guarantee</a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                          <td className="ftrLinkTd wd110 ftrFont">
                                <p className="ftrSep">
                                    <a className="Link" href="https://www.ameriprise.com/privacy-security-fraud/fraud-reporting/email-fraud" >About email fraud</a>
                                </p>
                          </td>
                        
                       
                            <td className="ftrLinkTd wd160 ftrFont">
                                <p className="">
                                    <a className="Link" href="https://www.ameriprise.com/legal/client-relationship-summary" >Client Relationship Summary</a>
                                </p>
                            </td>
                            <td className="ftrLinkTd wd160 ftrFont">
                                <div className="norton-seal" onClick={window.openNorton()}>
                                    <a href="https://seal.digicert.com/seals/popup/?tag=5aDFDJql&amp;url=www.ameriprise.com&amp;lang=en&amp;cbr=1621017956698" className="amp-nortonPopup u-block" rel="external noreferrer" target="_blank">
                                    <img src="https://www.ameriprise.com/webfiles/1637640058987/assets/ampcom/images/common/norton-seal-public-site.png" alt="Norton Secured, logo" />
                                    </a>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div className="ftrFont pad-bot-1">
                <p>Users of this site agree to be bound by the terms of the Ameriprise Financial Website Rules and Regulations.</p>
                <p>© <span>2021</span> Ameriprise Financial, Inc. All rights reserved.</p>
            </div>
        </div>
    </footer>
</acl-footer>        
    )
}