import React from 'react';

import logo from '../images/white_logo.png';
import '../css/Header.css'

export default function AppHeader(props) {
    return (
        <div className="hdrContainer">
            <div><img id="imgLogo" src={logo} alt="" /> </div>
            <div className="eventHeader"><strong>Ameriprise Event RSVP</strong></div>
        </div>
);
}